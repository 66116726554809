import React, { useState } from 'react';

const CustomCheckbox = ({ label, termsLink, borderColor = 'border-red-a200', checkmarkColor = 'red_A200' }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <label className="flex items-center cursor-pointer">
      <input
        type="checkbox"
        className="hidden"
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
      <span
        className={`h-[20px] w-[20px] inline-block border-2 ${borderColor} rounded mr-2 flex items-center justify-center`}
      >
        {isChecked && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill={checkmarkColor}
            className="checkmark"
            viewBox="0 0 16 16"
          >
            <path d="M13.485 1.515a1 1 0 0 1 0 1.415l-8 8a1 1 0 0 1-1.415 0l-4-4a1 1 0 0 1 1.415-1.415L6 9.586l7.07-7.07a1 1 0 0 1 1.415 0z" />
          </svg>
        )}
      </span>
      <span className="text-gray-600 flex items-center md:inline">
        <span>{label}&nbsp;</span>
        <span className="text-red-a200">
          <a href={'/terms-and-conditions'} className="text-red-a200">
            Terms and Conditions.
            
          </a>
        </span>
        <span className="text-gray-600 inline">&nbsp;Please review these documents to understand your rights and responsibilities.&nbsp;</span>
      </span>
    </label>
  );
};

export default CustomCheckbox;
