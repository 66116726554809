import { useState } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { CONTACT_US_API_HEADER_KEY, CONTACT_US_API_URL } from "config";

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  jobTitle: string;
}

interface ValidationErrors {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  jobTitle?: string;
}

export const useSignUp = () => {
  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    jobTitle: "",
  });
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const { enqueueSnackbar } = useSnackbar();
  const [isCheckboxSelected, setIsCheckboxSelected] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { name?: string; value: string }
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleCheckboxChange = () => {
    setIsCheckboxSelected((prev) => !prev);
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
    }));
  };

  const validateForm = () => {
    const errors: ValidationErrors = {};
    const phonePattern = /^\+44[0-9]{10}$/;

    if (!formData.firstName) errors.firstName = "First name is required";
    if (!formData.lastName) errors.lastName = "Last name is required";
    if (!formData.email) errors.email = "Email is required";
    if (!formData.phoneNumber) {
      errors.phoneNumber = "Phone number is required";
    } else if (!phonePattern.test(formData.phoneNumber)) {
      errors.phoneNumber = "Phone number must be in the format +44 followed by 10 digits";
    }
    if (!formData.jobTitle) errors.jobTitle = "Job title is required";

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const submitForm = async () => {
    if (!validateForm()) return;

    try {
      await axios.post("/signup-leads", formData, {
        headers: {
          "x-api-key": CONTACT_US_API_HEADER_KEY,
        },
        baseURL: CONTACT_US_API_URL,
      });

      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        jobTitle: "",
      });
      setValidationErrors({});
      setIsCheckboxSelected(false);
      enqueueSnackbar("Thank you for reaching out! We will get back to you shortly.", { variant: "success" });
      navigate("/page3");
    } catch (error) {
      enqueueSnackbar("Error submitting form. Please try again.", { variant: "error" });
      console.error("Error submitting form:", error);
    }
  };

  return {
    formData,
    handleChange,
    submitForm,
    validationErrors,
    handleCheckboxChange,
    isCheckboxSelected,
  };
};
