import { Text } from "../../components";
import UnwaveringCareSection from "../../components/UnwaveringCareSection";
import React, { Suspense } from "react";

const impactInitiativesList = [
  {
    creditCardImage: "images/img_credit_card.svg",
    headingText: "Unwavering Care",
  },
  {
    creditCardImage: "images/img_server.svg",
    headingText: "Cultivating lasting impact.",
  },
  {
    creditCardImage: "images/img_qrcode.svg",
    headingText: "Here for families, every step of the way",
  },
];

export default function AboutUsSection1() {
  return (
    <>
      {/* about us section */}
      <div className="mt-[150px]">
        <div className="bg-gray-100">
          <div className="flex h-[840px] items-start justify-center bg-[url(/public/images/img_group_12.svg)] bg-cover bg-no-repeat py-[118px] lg:h-auto lg:py-8 md:h-auto md:py-5 sm:py-4">
            <div className="container-xs mb-8 flex justify-center lg:px-5 md:px-5">
              <div className="flex w-full flex-col gap-10">
                <Text
                  size="textmd"
                  as="p"
                  className="text-center text-[55px] font-medium uppercase leading-[80px] text-black-900 lg:text-[46px] md:text-[35px] sm:text-[29px]"
                >
                  Providing tailored help to meet the needs of the client{" "}
                </Text>
                <div className="flex gap-[30px] md:flex-col">
                  <Suspense fallback={<div>Loading feed...</div>}>
                    {impactInitiativesList.map((d, index) => (
                      <UnwaveringCareSection
                        {...d}
                        key={"featuresList" + index}
                      />
                    ))}
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
