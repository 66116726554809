import React from "react";

const shapes = {
  square: "rounded-[0px]",
} as const;
const variants = {
  tarFillGray10002: "bg-gray-100_02",
} as const;
const sizes = {
  xs: "h-[204px] p-8 text-[24px]",
} as const;

type TextAreaProps = Omit<
  React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
  "size" | "prefix" | "type" | "onChange"
> & {
  className?: string;
  name?: string;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  shape?: keyof typeof shapes;
  variant?: keyof typeof variants | null;
  size?: keyof typeof sizes;
  errorMessage?: string; 
};

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      className = "",
      name = "",
      placeholder = "",
      shape,
      size = "xs",
      variant = "tarFillGray10002",
      onChange,
      errorMessage, 
      ...restProps
    },
    ref,
  ) => {
    return (
      <div className="flex flex-col">
        <textarea
          ref={ref}
          className={`${className} ${shape && shapes[shape]} ${size && sizes[size]} ${variant && variants[variant]} border-0`}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          {...restProps}
        />
        {errorMessage && (
          <span className="text-red-500 text-sm">
            {errorMessage}
          </span>
        )}
      </div>
    );
  },
);

export { TextArea };
