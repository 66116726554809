import Footer from "components/Footer";
import React from "react";
import { Text } from "../../components";
import Header from "../../components/Header";

export default function PrivacyPolicyPage() {
  return (
    <>
      <div className="w-full bg-white-a700">
        <div className="flex flex-col gap-[70px] lg:gap-[70px] md:gap-[52px] sm:gap-[35px]">
          <div>
            <div className="h-[560px] bg-[url(/public/images/main-banner.png)] bg-cover bg-no-repeat lg:h-auto md:h-auto">
              <div className="flex flex-col items-center gap-[34px] bg-gradient">
                <Header className="mt-[30px] w-[88%] lg:w-full lg:px-5 md:w-full md:px-5 sm:flex-col" />
                <div className="ml-[22px] mr-2 flex h-[438px] w-[86%] items-center justify-center  py-[94px] lg:h-auto lg:w-full lg:px-5 lg:py-8 md:mx-0 md:h-auto md:w-full md:p-5 sm:px-5 sm:py-4">
                  <div className="container-xs mb-2.5 flex justify-center px-14 md:px-5 sm:px-4">
                    <div className="flex w-full flex-col items-center gap-[26px]">
                      <Text
                        size="textmd"
                        as="p"
                        className="text-[55px] font-medium uppercase text-black-900 lg:text-[46px] md:text-[35px] sm:text-[29px]"
                      >
                        Privacy Policy
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative flex flex-col items-center mt-[50px]">
              <div className="container-xs relative lg:px-5 md:px-5">
                <Text
                  as="p"
                  className="text-[18px] font-normal leading-[170%] text-gray-600 lg:text-[15px]"
                >
                  <span className="text-[24px] font-medium text-black-900">
                    <>
                      Introduction
                      <br />
                    </>
                  </span>
                  <span className="text-gray-600">
                    <>
                      At Blue Chip Tech ("we," "us," "our"), we are committed to protecting the privacy of our users. This policy outlines how we collect, use, store, and protect personal information in compliance with the UK GDPR and the Data Protection Act 2018. By using our website and services, you agree to the practices outlined in this policy.
                      <br />
                      <br />
                    </>
                  </span>
                  <span className="text-[24px] font-medium text-black-900">
                    <>
                      Data Collection
                      <br />
                    </>
                  </span>
                  <span className="text-gray-600">
                    <>
                      We may collect and process the following types of personal information:
                      Personal Information: Includes names, contact details (such as phone numbers and email addresses), and postal addresses.
                      <br />
                      <br />
                    </>
                  </span>
                  <span className="text-[24px] font-medium text-black-900">
                    <>
                      Health Information:
                      <br />
                    </>
                  </span>
                  <span className="text-gray-600">
                    <>
                      When relevant to the provision of care services, we may collect information related to health conditions, treatment history, and care needs.
                    </>
                  </span>
                  <br />
                  <br />
                  <span className="text-[24px] font-medium text-black-900">
                    <>
                      Technical Data:
                      <br />
                    </>
                  </span>
                  <span className="text-gray-600">
                    <>
                      Such as IP addresses, browser types, and cookie data, collected via our website to enhance user<br /> experience.
                      <br />
                      <br />
                      We collect data directly through forms submitted on our website, phone conversations, email communications, and cookies.
                      <br />
                      <br />
                    </>
                  </span>
                  <span className="text-[24px] font-medium text-black-900">
                    <>
                      Use of Data
                      <br />
                    </>
                  </span>
                  <span className="text-gray-600">
                    <>
                      We use personal data for the following purposes:
                      <br />
                      <br />
                    </>
                  </span>
                  <span className="text-[24px] font-medium text-black-900">
                    <>
                      Service Provision:
                      <br />
                    </>
                  </span>
                  <span className="text-gray-600">
                    <>
                      To deliver the services you request, including personalised care plans and support.
                      <br />
                      <br />
                    </>
                  </span>
                  <span className="text-[24px] font-medium text-black-900">
                    <>
                      Improvement of Services:
                      <br />
                    </>
                  </span>
                  <span className="text-gray-600">
                    <>
                      To improve our website, services, and user experience.
                      Marketing and Communication: With your consent, we may use your contact information to send promotional materials and updates about our services.
                      <br />
                      <br />
                    </>
                  </span>

                  <span className="text-[24px] font-medium text-black-900">
                    <>
                      Third-Party Sharing:
                      <br />
                    </>
                  </span>
                  <span className="text-gray-600">
                    <>
                      We may share information with trusted third parties for specific purposes, such as healthcare providers involved in your care, payment processors, and legal authorities where required by law.
                      We do not sell personal information to third parties. Any data sharing is limited to fulfilling our service obligations or as required by legal regulations.
                      <br />
                      <br />
                    </>
                  </span>

                  <span className="text-[24px] font-medium text-black-900">
                    <>
                      Data Security
                      <br />
                    </>
                  </span>
                  <span className="text-gray-600">
                    <>
                      We employ stringent security measures to protect personal data from unauthorized access, alteration, or disclosure. These measures include:
                      <br />
                      <br />
                    </>
                  </span>
                  <span className="text-[24px] font-medium text-black-900">
                    <>
                      Data Encryption:
                      <br />
                    </>
                  </span>
                  <span className="text-gray-600">
                    <>
                      Sensitive data is encrypted both in transit and at rest.
                      <br />
                      <br />
                    </>
                  </span>
                  <span className="text-[24px] font-medium text-black-900">
                    <>
                      Access Controls:
                      <br />
                    </>
                  </span>
                  <span className="text-gray-600">
                    <>
                      Access to personal data is limited to authorised staff members who require it to perform their roles.
                      <br />
                      <br />
                    </>
                  </span>
                  <span className="text-[24px] font-medium text-black-900">
                    <>
                      Regular Security Audits:
                      <br />
                    </>
                  </span>
                  <span className="text-gray-600">
                    <>
                      We conduct audits to ensure our data security protocols are up to date and effective.
                      In the event of a data breach, we will promptly notify affected individuals and relevant authorities as required by law.
                      <br />
                      <br />
                    </>
                  </span>
                  <span className="text-[24px] font-medium text-black-900">
                    <>
                      User Rights
                      <br />
                    </>
                  </span>
                  <span className="text-gray-600">
                    <>
                      Under the UK GDPR, users have certain rights concerning their personal data. These rights include:
                      <br />
                    </>
                  </span>
                  <>
                    <span className="text-[24px] font-medium text-black-900">
                      Access:
                    </span>
                    <span className="text-gray-600"> The right to request access to your personal data.</span>
                    <br />
                  </>
                  <>
                    <span className="text-[24px] font-medium text-black-900">
                      Correction:
                    </span>
                    <span className="text-gray-600"> The right to request correction of inaccurate or incomplete information. </span>
                    <br />
                  </>
                  <>
                    <span className="text-[24px] font-medium text-black-900">
                      Deletion:
                    </span>
                    <span className="text-gray-600"> The right to request deletion of your data under certain conditions.  </span>
                    <br />
                  </>
                  <>
                    <span className="text-[24px] font-medium text-black-900">
                      Data Portability:
                    </span>
                    <span className="text-gray-600"> The right to request transfer of your data to another service provider.
                      To exercise these rights, please contact us using the information provided below. We will respond to all requests in accordance with applicable laws.</span>
                    <br />
                    <br />
                  </>
                  <>
                    <span className="text-[24px] font-medium text-black-900">
                      Cookies
                    </span>
                    <p className="text-gray-600"> Our website uses cookies to enhance user experience and analyse site traffic. Cookies used include:
                    </p>
                    <br />
                  </>
                  <>
                    <span className="text-[24px] font-medium text-black-900">
                      Necessary Cookies:
                    </span>
                    <span className="text-gray-600"> Required for website functionality. </span>
                    <br />
                  </>
                  <>
                    <span className="text-[24px] font-medium text-black-900">
                      Analytics Cookies:
                    </span>
                    <span className="text-gray-600"> Help us understand how users interact with our site.</span>
                    <br />
                  </>
                  <>
                    <span className="text-[24px] font-medium text-black-900">
                      Preference Cookies:
                    </span>
                    <span className="text-gray-600"> Store user preferences for a more personalised experience.
                      You can manage cookie settings via your browser or by adjusting preferences when prompted on our site. </span>
                    <br />
                    <br />
                  </>
                  <>
                    <span className="text-[24px] font-medium text-black-900">
                      Changes to the Policy
                    </span>
                    <p className="text-gray-600"> We may update this Privacy Policy to reflect changes in our practices or relevant legal requirements. Any updates will be posted on this page, and significant changes will be communicated directly to users where required.
                    </p>
                    <br />
                  </>
                  <>
                    <span className="text-[24px] font-medium text-black-900">
                      Contact Information
                    </span>
                    <p className="text-gray-600">If you have questions or concerns about your data or this policy, please contact us at: </p>
                    <p className="text-gray-600"> Blue Chip Tech </p>
                    <p className="text-gray-600"> Address: 14 Douglas Drive, Wantage, Oxfordshire, England, OX12 0GL
                    </p>
                    <p className="text-gray-600"> Email: Info@Bluechipcare.co.uk
                    </p>
                    <p className="text-gray-600">Phone: 0186 570 4195
                    </p>
                    <br />
                  </>
                  <>
                    <span className="text-[24px] font-medium text-black-900">
                      Compliance and Legal Advice
                    </span>
                    <p className="text-gray-600"> This policy is designed to comply with the UK GDPR and Data Protection Act 2018. For detailed guidance or to ensure full compliance with all relevant laws, we recommend consulting a legal professional.
                    </p>
                    <br />
                  </>
                </Text>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div >
    </>
  );
}
