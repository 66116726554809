import { Img, Button, Text } from "../../components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import OurServicesSection from "./OurServicesSection";
import OurWorkShowcaseSection from "./OurWorkShowcaseSection";
import ServicesSection from "./ServicesSection";
import React from "react";
import { Link } from "react-router-dom";

export default function Home1Page() {

  return (
    <>
      <div className="w-full bg-white-a700">
        <div>
          <div className="relative h-[1176px] content-end lg:h-[1000px] md:h-[800px] sm:h-[1100px]">
            <div className="absolute bg-gradient left-0 right-0 top-0 m-auto h-[1023px] md:h-[700px] lg:h-[800px] sm:h-[450px] flex-1">
              {/* bg-[url(/public/images/img_group_8.png)] bg-cover bg-no-repeat */}
              <div className="flex justify-center bg-gradient p-[30px] sm:p-4 lg:h-[800px]">
                <div className="mb-[120px] w-[92%] lg:w-full md:w-full">
                  <Header className="mx-[22px] md:mx-0 sm:flex-col" />
                  <div className="relative h-[784px] lg:h-[570px] content-center md:h-auto lg:mt-20">
                    {/* <div className="absolute bottom-[13%] right-[3%] lg:right-[5%] m-auto h-[394px] lg:h-[300px] lg:w-[300px] w-[394px] rounded-[196px] bg-red-600_cc md:hidden" /> */}
                    <div className="ml-auto mr-5 h-[142px] w-[142px] rounded-[70px] bg-white-a700_4c lg:mr-0 md:mr-0" />
                    <div className="absolute bottom-0 left-0 right-0 top-0 m-auto flex h-auto flex-1 items-start justify-end md:flex-col md:mt-[1px] md:h-[300px] ">
                      <div className="mt-[178px] flex w-[45%] flex-col items-start gap-14 md:w-full sm:gap-7 md:items-center">
                        <div className="flex flex-col self-stretch">
                          <Text
                            size="textmd"
                            as="p"
                            className="text-[55px] font-medium uppercase text-[#010101] lg:text-[46px] md:text-[35px] sm:text-[29px]"
                          >
                            Your comfort, our commitment
                          </Text>
                          <Text
                            as="p"
                            className="w-full text-[18px] font-normal leading-[170%] text-gray-600 lg:text-[15px] md:w-[80%]  sm:text-[13px]"
                          >
                            Everyone’s journey to independence is unique, so we
                            create personalised care plans that nurture
                            confidence and self-reliance. Our compassionate
                            support—from mobility assistance to personal
                            care—focuses on enhancing daily life while upholding
                            your dignity and freedom.
                          </Text>
                        </div>
                        <Link to="/contact-us">
                          <Button
                            color="red_A200"
                            size="md"
                            variant="fill"
                            shape="round"
                            className="min-w-[234px] rounded-[10px] px-[34px] sm:px-4"
                          >
                            Contact Us
                          </Button>
                        </Link>
                      </div>
                      <Img
                        src="images/main.png"
                        alt="Caregiver Image"
                        className="relative ml-[-10px] h-[784px] lg:h-[600px] w-[46%] lg:pt-[30px] self-center object-contain md:ml-0 md:w-full md:hidden"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Wrap OurWorkShowcaseSection in a relative container */}
            <div className="relative mt-6 md:mt-0">
              <OurWorkShowcaseSection />
            </div>

            {/* Slider section at the bottom */}
            {/* <div className="relative bottom-[6%] left-0 right-0 m-auto flex items-center justify-center">
              {[...Array(3)].map((_, i) => (
                <>
                  {sliderState >=
                    i * (sliderRef?.current?.state?.itemsInSlide || 1) &&
                  sliderState <
                    (i + 1) * (sliderRef?.current?.state?.itemsInSlide || 1) ? (
                    <div
                      onClick={() => {
                        sliderRef?.current?.slideTo(
                          i * sliderRef?.current?.state?.itemsInSlide
                        );
                      }}
                      className="mr-[15.42px] inline-block h-[22px] w-[22px] cursor-pointer rounded-[10px] border-[3.3px] border-solid border-red-900_01 bg-white-a700"
                    />
                  ) : (
                    <div
                      onClick={() => {
                        sliderRef?.current?.slideTo(
                          i * sliderRef?.current?.state?.itemsInSlide
                        );
                      }}
                      className="mr-[15.42px] inline-block h-[12px] w-[12px] cursor-pointer rounded-md bg-red-600"
                    />
                  )}
                </>
              ))}
            </div> */}
          </div>

          {/* services section */}
          <ServicesSection />

          {/* our services section */}
          <OurServicesSection />
          <Footer className="mt-[134px]" />
        </div>
      </div>
    </>
  );
}
