import React from "react";

const sizes = {
  textxs: "text-[18px] font-normal not-italic lg:text-[15px]",
  texts: "text-[19px] font-normal lg:text-[16px]",
  textmd: "text-[21px] font-normal lg:text-[17px]",
  textlg: "text-[22px] font-normal not-italic lg:text-[18px]",
  textxl: "text-[23px] font-normal not-italic lg:text-[19px] md:text-[21px]",
  text2xl: "text-[25px] font-normal not-italic lg:text-[21px] md:text-[23px] sm:text-[21px]",
  text3xl: "text-[28px] font-normal not-italic lg:text-[23px] md:text-[26px] sm:text-[24px]",
};

export type TextProps = Partial<{
  className: string;
  as: any;
  size: keyof typeof sizes;
}> &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

const Text: React.FC<React.PropsWithChildren<TextProps>> = ({
  children,
  className = "",
  as,
  size = "textxs",
  ...restProps
}) => {
  const Component = as || "p";

  return (
    <Component className={`font-oswald ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Text };
