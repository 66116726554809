import { Text } from "../../components";
import React from "react";
import AliceCarousel from "react-alice-carousel";
import "./OurWorkShowcaseSection.css";

export default function OurWorkShowcaseSection() {
  const [sliderState, setSliderState] = React.useState(0);
  const sliderRef = React.useRef<AliceCarousel>(null);

  return (
    <>
      {/* our work showcase section */}
      <div className="mx-auto w-full">
        <div className="flex w-full">
          <div className="flex bg-red-a200 w-full">
            <div className="flex h-[354px] w-full items-center justify-center bg-[url(/public/images/img_group_11.svg)] bg-cover bg-no-repeat py-[62px] lg:h-auto lg:py-8 md:h-auto md:py-5 sm:py-4">
              <div className="container-xs mb-1.5 flex justify-center px-14 lg:px-5 md:px-5 w-full">
                <div className="flex w-full flex-col items-center">
                  <Text
                    size="textmd"
                    as="p"
                    className="text-[55px] font-medium uppercase text-white-a700 lg:text-[46px] md:text-[35px] sm:text-[29px]"
                  >
                    Some of our work
                  </Text>
                  <Text
                    as="p"
                    className="relative mb-[70px] mt-[-2px] self-stretch text-center text-[18px] font-normal leading-[170%] text-white-a700 lg:text-[15px]"
                  >
                    We are proud of the quality and care we bring to each
                    client, creating meaningful impact in their lives.
                    Here’s a look at some recent projects that showcase
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
