import React from "react";
import { Button, Img, SelectBox, Input } from "../../components";
import CustomCheckbox from "components/CheckBox";
import { useSignUp } from "./useSignUp";
import { ChangeEvent } from "react";

interface OptionType {
  label: string;
  value: string;
}

const dropDownOptions = [
  { label: "REGISTERED NURSE", value: "registered_nurse" },
  { label: "Health Care Assistance", value: "health_care_assistance" },
  { label: "Support Worker", value: "support_worker" },
  { label: "Domestic Staff", value: "domestic_staff" },
];

// Define a type for the change event
type CustomChangeEvent = ChangeEvent<
  HTMLInputElement | HTMLTextAreaElement | { name?: string; value: string }
>;

export default function ContactFormSection() {
  const { formData, handleChange, submitForm, validationErrors } = useSignUp();

  const handleSelectChange = (selectedOption: OptionType | null) => {
    const value = selectedOption ? selectedOption.value : "";
    const event = {
      target: {
        name: "jobTitle",
        value: value,
      },
    } as CustomChangeEvent;
    handleChange(event);
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitForm();
      }}
    >
      <div className="bottom-[-1px] left-0 right-0 m-auto flex flex-1 justify-center algin-center top-1000px">
        <div className="container-xs flex justify-center align-center lg:px-5 md:px-5">
          <div className="flex w-full flex-col items-end">
            <div className="self-stretch">
              <div className="flex flex-col items-start gap-10">
                <div className="flex gap-[30px] self-stretch md:flex-col w-full ">
                  <Input
                    shape="square"
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    placeholder="First Name"
                    errorMessage={validationErrors.firstName}
                    prefix={
                      <Img
                        src="images/img_vector.svg"
                        alt="Vector"
                        className="my-1.5 h-[28px] w-[30px]"
                      />
                    }
                    className="w-full gap-[30px] px-[38px] sm:w-full sm:px-4"
                  />
                  <Input
                    shape="square"
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    placeholder="Last Name"
                    errorMessage={validationErrors.lastName}
                    prefix={
                      <Img
                        src="images/img_vector.svg"
                        alt="Vector"
                        className="my-1.5 h-[28px] w-[30px]"
                      />
                    }
                    className="w-full gap-[30px] px-[38px] sm:w-full sm:px-4"
                  />
                </div>
                <div className="flex gap-[30px] self-stretch md:flex-col">
                  <Input
                    shape="square"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Your Email"
                    errorMessage={validationErrors.email}
                    prefix={
                      <Img
                        src="images/img_thumbs_up.svg"
                        alt="Thumbs Up"
                        className="my-1.5 h-[28px] w-[32px]"
                      />
                    }
                    className="w-full gap-[30px] px-[38px] sm:w-full sm:px-4"
                  />
                  <Input
                    shape="square"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    placeholder="Phone Number"
                    errorMessage={validationErrors.phoneNumber}
                    prefix={
                      <Img
                        src="images/img_call.svg"
                        alt="Call"
                        className="my-1.5 h-[28px] w-[32px]"
                      />
                    }
                    className="w-full gap-[30px] px-[38px] sm:w-full sm:px-4"
                  />
                </div>
                <div className="flex gap-[10px] self-stretch flex-col">

                  <SelectBox
                    shape="square"
                    indicator={
                      <Img
                        src="images/img_arrowdown.svg"
                        alt="Arrow Down"
                        className="h-[28px] w-[16px]"
                      />
                    }

                    // getOptionLabel={(option: OptionType) => (
                    //   <div className="flex items-center " style={{ width: '300px' }}>
                    //     <Img
                    //       src="images/img_settings_1.svg"
                    //       alt="Settings 1"
                    //     className="h-[28px] w-[32px]"
                    //     />
                    //     <span>{option.label}</span>
                    //   </div>
                    // )}
                    name = "jobTitle"
                    value={
                    dropDownOptions.find(
                      (option) => option.value === formData.jobTitle
                    ) || null
                  }
                  onChange={handleSelectChange}
                  placeholder="Role Interested In"
                  options={dropDownOptions}
                  className="w-[48%] md:w-full gap-[30px] px-[38px] uppercase sm:p-4"
                  />
                  {validationErrors.jobTitle && (
                    <span className="text-red-600">
                      {validationErrors.jobTitle}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="relative mr-3.5 mt-10 h-[36px] content-center self-stretch lg:h-auto md:mr-0 md:h-auto">
              <div className="mx-auto flex flex-1 items-center gap-[17px] md:flex-row">
                <CustomCheckbox
                  label="By proceeding, you agree to our"
                  termsLink="/terms-and-conditions"
                  borderColor="border-red-a200"
                  checkmarkColor="text-red-a200"
                />
              </div>
            </div>
            <Button
              color="red_A200"
              size="md"
              variant="fill"
              shape="round"
              className="mt-[60px] min-w-[234px] rounded-[10px] px-[34px] sm:px-4"
              type="submit"
            >
              Get Started
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
