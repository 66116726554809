import { Text } from "../../components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ContactFormSection from "./ContactFormSection";
import React from "react";

export default function SignUpPage() {
  return (
    <>
      <div className="w-full bg-white-a700">
        <div className="flex flex-col gap-[178px] lg:gap-[133px] md:gap-[133px] sm:gap-[89px]">
          <div className="relative">
            <div className="h-[560px] bg-[url(/public/images/main-banner.png)] bg-cover bg-no-repeat lg:h-auto md:h-auto ">
              <div className="mb-[146px] flex flex-col items-center gap-[116px] lg:gap-[116px] md:gap-[87px] sm:gap-[58px]">
                <Header className="self-stretch px-[120px] py-[26px] lg:px-8 md:px-5 sm:flex-col sm:p-4" />
                <div className="container-xs flex flex-col items-center px-14 lg:px-5 md:px-5 pb-[94px]">
                  <div className="flex w-[72%] flex-col items-center gap-[26px] lg:w-full md:w-full">
                    <Text
                      size="textmd"
                      as="p"
                      className="text-[55px] font-medium uppercase text-black-900 lg:text-[46px] md:text-[35px] sm:text-[29px]"
                    >
                      Get Started Easily
                    </Text>
                    <Text
                      as="p"
                      className="self-stretch text-center text-[18px] font-normal leading-[170%] text-gray-600 lg:text-[15px]"
                    >
                      Complete our quick registration form to begin, and you’ll
                      be guided to the full application. If you have questions
                      or run into any issues, our recruitment team is here to
                      help—don’t hesitate to reach out.
                    </Text>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="h-900px justify-center align-center"
              style={{ marginTop: "100px" }}
            >
              <ContactFormSection />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
