import { Text } from "../../components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import SupportWorkerProfile from "../../components/SupportWorkerProfile";
import React, { Suspense } from "react";

const jobListingsGrid = [
  {
    supportWorkerImage: "images/c1.png",
    supportWorkerTitle: "Registered Nurse ",
    jobDescription:
      "Registered Nurse needed for compassionate patient care within a collaborative healthcare team",
    applyButtonText: "Apply Now",
  },
  {
    supportWorkerImage: "images/c2.png",
    supportWorkerTitle: "healthcare assistance",
    jobDescription:
      "Registered Nurse needed for compassionate patient care within a collaborative healthcare team",
    applyButtonText: "Apply Now",
  },
  {
    supportWorkerImage: "images/img_2149232543_1.png",
    supportWorkerTitle: "support worker",
    jobDescription:
      "Registered Nurse needed for compassionate patient care within a collaborative healthcare team",
    applyButtonText: "Apply Now",
  },
  {
    supportWorkerImage: "images/img_2148934369_1.png",
    supportWorkerTitle: "domestic staff",
    jobDescription:
      "Registered Nurse needed for compassionate patient care within a collaborative healthcare team",
    applyButtonText: "Apply Now",
  },
  {
    supportWorkerImage: "images/img_2149856242_1.png",
    supportWorkerTitle: "social Worker",
    jobDescription:
      "Registered Nurse needed for compassionate patient care within a collaborative healthcare team",
    applyButtonText: "Apply Now",
  },
];

export default function CareerPage() {
  return (
    <>
      <div className="w-full bg-white-a700">
        <div className="flex flex-col items-center">
          <div className="flex flex-col items-end bg-gradient w-full">
            <Header className="relative z-[1] self-stretch px-[120px] py-[26px] lg:px-8 md:px-5 sm:flex-col sm:p-4" />
            <div className="relative mb-[42px] mt-[-122px] flex  w-[92%] items-end h-[560px] bg-[url(/public/images/main-banner.png)] bg-cover bg-no-repeat lg:h-auto md:h-auto py-[140px] lg:w-full lg:px-5 lg:py-8  md:w-full md:p-5 sm:px-5 sm:py-4">
              <div className="container-xs mt-[98px] flex justify-center px-14 md:px-5 sm:px-4">
                <div className="flex flex-col items-center gap-7 px-14 md:px-5 sm:px-4">
                  <Text
                    size="textmd"
                    as="p"
                    className="text-[55px] font-medium uppercase text-black-900 lg:text-[46px] md:text-[35px] sm:text-[29px]"
                  >
                    Considering a Career with Us?
                  </Text>
                  <Text
                    as="p"
                    className="text-[18px] font-normal text-gray-600 lg:text-[15px] text-center"
                  >
                    At  Blue Chip Tech, we're always seeking dedicated
                    individuals to join our team across Nottinghamshire and
                    surrounding areas. With flexible, personalised hours and a
                    variety of fulfilling roles—from support workers to nurses
                    and live-in carers—you’ll find the opportunity to grow in a
                    supportive, expanding industry. If you’re committed,
                    hard-working, and passionate about making a difference,
                    connect with us to explore a rewarding career in care.
                  </Text>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="container-xs mt-[30px] lg:px-5 md:px-5">
            <div className="grid grid-cols-2 justify-center gap-[30px] gap-y-[30px] lg:grid-cols-2 md:grid-cols-1">
              <Suspense fallback={<div>Loading feed...</div>}>
                {jobListingsGrid.map((d, index) => (
                  <SupportWorkerProfile {...d} key={"supportGrid" + index} />
                ))}
              </Suspense>
            </div>
          </div> */}
          <Footer className="mt-[98px] self-stretch" />
        </div>
      </div>
    </>
  );
}
