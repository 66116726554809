import { Text, Heading, Img } from "./..";
import React from "react";

interface Props {
  img1?: string;
  img2?: string;
  className?: string;
  learningDisabilityTitle?: React.ReactNode;
  learningDisabilityDescription?: React.ReactNode;
  endOfLifeTitle?: React.ReactNode;
  endOfLifeDescription?: React.ReactNode;
}

export default function CareServicesOverview({
  img1,
  img2,
  learningDisabilityTitle = "Learning Disability Care",
  learningDisabilityDescription = "Personalised care plans that focus on promoting independence and enhancing the quality of life for individuals with learning disabilities.",
  endOfLifeTitle = "End-of-Life Care",
  endOfLifeDescription = "Compassionate care that provides comfort, dignity, and support to individuals and their families during the final stages of life.",
  ...props
}: Props) {
  return (
    <div
      {...props}
      className={`${props.className} flex sm:flex-col items-center flex-1 w-full gap-5`}
    >
      <div className="w-full">
        <div className="flex flex-col items-center ">
          <Img src={img1} alt="" className="h-[468px] w-[92%] object-contain" />
          <div className="relative mt-[-94px] lg:mt-[-160px] flex w-[80%] flex-col justify-center gap-4 rounded-[10px] bg-white-a700 px-7 py-[26px] shadow-xs md:ml-0 md:w-full sm:p-5">
            <Heading
              as="p"
              className="text-[34px] font-medium uppercase text-red-a200 md:text-[32px] sm:text-[30px]"
            >
              {learningDisabilityTitle}
            </Heading>
            <Text
              size="texts"
              as="p"
              className="w-full text-[19px] font-normal uppercase leading-[170%] text-gray-600"
            >
              {learningDisabilityDescription}
            </Text>
          </div>
        </div>
      </div>
      <div className="w-full">
      <div className="flex flex-col items-center ">
          <Img src={img2} alt="" className="h-[468px] w-[92%] object-contain" />
          <div style={{display:'flex', justifyContent:'center'}}>
          <div className="relative mt-[-94px] lg:mt-[-160px] md-mt flex w-[80%] flex-col justify-center gap-4 rounded-[10px] bg-white-a700 px-7 py-[26px] shadow-xs md:ml-0 md:w-full sm:p-5">
            <Heading
              as="p"
              className="text-[34px] font-medium uppercase text-red-a200 md:text-[32px] sm:text-[30px]"
            >
              {learningDisabilityTitle}
            </Heading>
            <Text
              size="texts"
              as="p"
              className="w-full text-[19px] font-normal uppercase leading-[170%] text-gray-600"
            >
              {learningDisabilityDescription}
            </Text>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}
