import { Button, Img, Input, TextArea } from "../../components";
import React, { Suspense } from "react";
import { useContactUs } from "./useContactUs";

export default function ContactUsSection() {
  const { submitForm, handleChange, formData, validationErrors } =
    useContactUs();

  const handleSubmit = (e) => {
    e.preventDefault();
    submitForm();
  };

  const profileGrid = [
    {
      userName: "Your Name",
      name: "name",
      value: formData?.name,
      userImage: "images/img_vector.svg",
    },
    {
      userName: "Your Email",
      name: "email",
      value: formData?.email,
      userImage: "images/img_thumbs_up.svg",
    },
    {
      userName: "Phone Number",
      name: "phoneNumber",
      value: formData?.phoneNumber,
      userImage: "images/img_call.svg",
    },
  ];

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex justify-center">
        <div className="container-xs flex justify-center lg:px-5 md:px-5">
          <div className="flex w-full flex-col items-end">
            <div className="flex flex-col gap-9 self-stretch">
              <div className="grid grid-cols-2 justify-center gap-[30px] lg:grid-cols-2 md:grid-cols-1">
                <Suspense fallback={<div>Loading feed...</div>}>
                  {profileGrid.map((d) => (
                    <div key={d.name}>
                      <Input
                        name={d.name}
                        onChange={handleChange}
                        className="gap-[31px]"
                        placeholder={d.userName}
                        value={d.value}
                        prefix={
                          <div className="flex h-[30px] w-[30px] items-center justify-center">
                            <Img
                              src={d.userImage}
                              alt=""
                              className="my-1.5 h-[28px] w-[30px]"
                            />
                          </div>
                        }
                      />
                      {validationErrors[d.name] && (
                        <div className="text-red-500 text-sm mt-1">
                          {validationErrors[d.name]}
                        </div>
                      )}
                    </div>
                  ))}
                </Suspense>
              </div>
              <TextArea
                shape="square"
                name="query"
                value={formData?.query}
                onChange={handleChange}
                placeholder="Enter Your Message"
                className="mb-[108px] flex gap-4 px-8 font-lato text-gray-600 lg:py-8 md:py-5 sm:p-4"
                errorMessage={validationErrors.query}
              />
            </div>
            <Button
              color="red_A200"
              size="md"
              variant="fill"
              shape="round"
              className="relative mt-[-70px] min-w-[234px] rounded-[10px] px-[34px] sm:px-4"
              type="submit"
            >
              Send Message
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
