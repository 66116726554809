import { Text, Img } from "../../components";
import React, { useState } from "react";

export default function OurValuesSection() {
  const [selectedValue, setSelectedValue] = useState("compassion"); // State to track the selected card

  const valuesData = [
    {
      id: "compassion",
      title: "Compassion",
      description:
        "We believe in truly understanding and caring for each individual, offering a warm, empathetic approach that builds trust and comfort.",
      imgSrc: "images/img_2149856221_1.png",
    },
    {
      id: "integrity",
      title: "Integrity",
      description:
        "We maintain the highest ethical standards in every interaction,always prioritising the well-being of our clients their families",
      imgSrc: "images/aboutus2.png",
    },
    {
      id: "excellence",
      title: "Excellence",
      description:
        "From the quality of our services to the dedication of our staff,excellence is at the heart of everything we do, ensuring consistent,exceptional care",
      imgSrc: "images/aboutus3.png",
    },
  ];

  return (
    <div className="mt-[98px] flex flex-col items-center">
      <div className="container-xs flex flex-col items-center px-[52px] lg:px-5 md:px-5">
        {/* Title */}
        <Text
          size="textmd"
          as="p"
          className="text-[55px] font-medium uppercase text-black-900 lg:text-[46px] md:text-[35px] sm:text-[29px]"
        >
          Our Values
        </Text>
        {/* Subtitle */}
        <Text
          as="p"
          className="mt-2.5 self-stretch text-center text-[18px] font-normal leading-[170%] text-gray-600 lg:text-[15px]"
        >
          Our values guide every aspect of our care, ensuring that each patient
          receives compassionate, respectful, and personalised attention.
        </Text>
        {/* Content Section */}
        <div className="mt-[100px] flex flex-wrap gap-[30px] self-stretch md:flex-col">
          {/* Image Section */}
          <div className="w-[44%] bg-gray-300_01 md:w-full">
            <Img
              src={
                valuesData.find((value) => value.id === selectedValue)?.imgSrc
              }
              alt="Values Image"
              className="h-[502px] w-full object-cover lg:h-auto md:h-auto"
            />
          </div>
          {/* Cards Section */}
          <div className="flex flex-1 flex-col gap-7 md:self-stretch">
            {valuesData.map((value) => (
              <div
                key={value.id}
                onClick={() => setSelectedValue(value.id)}
                className={`flex flex-col items-start justify-center gap-[22px] rounded-[10px] border-[3px] border-solid ${selectedValue === value.id
                  ? "border-red-a200 bg-white-a700 px-[30px] py-[50px] md:py-5 sm:p-4"
                  : "border-blue_gray-100_01 bg-white-a700 px-[30px] py-7 sm:p-4"
                  } cursor-pointer`}
              >
                <Text
                  size="texts"
                  as="p"
                  className={`text-[24px] font-medium uppercase ${selectedValue === value.id
                    ? "text-red-a200"
                    : "text-gray-600"
                    } lg:text-[20px]`}
                >
                  {value.title}
                </Text>
                {selectedValue === value.id && (
                  <Text
                    as="p"
                    className="w-full text-[18px] font-normal leading-[170%] text-gray-600 lg:text-[15px]"
                  >
                    {value.description}
                  </Text>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
