import { Text } from "../../components";
import LearningDisabilityCareComponent from "../../components/LearningDisabilityCareComponent";
import React, { Suspense } from "react";

const careServicesList = [
  {
    settingsImage: "images/img_settings.svg",
    headingText: "Learning Disability Care",
    descriptionText:
      "Personalised care plans that focus on promoting independence and enhancing the quality of life for individuals with learning disabilities.",
    respiteCareImage: "images/img_healthcare_1_1.svg",
    respiteCareHeading: "Respite Care",
    respiteCareDescription:
      "Temporary care services that offer relief for family caregivers, giving them time to rest while ensuring their loved ones receive quality care.",
  },
  {
    settingsImage: "images/healthcare1.svg",
    headingText: "End-of-Life Care",
    descriptionText:
      "Compassionate care that provides comfort, dignity, and support to individuals and their families during the final stages of life.",
    respiteCareImage: "images/overnight.svg",
    respiteCareHeading: "Overnight Care",
    respiteCareDescription:
      "Support during the night to ensure safety, provide assistance, and address any needs that may arise during nighttime hours.",
  },
  {
    settingsImage: "images/img_television.svg",
    headingText: "Live-in Care",
    descriptionText:
      "Around-the-clock care delivered in the comfort of your own home, offering continuous support for daily living activities.",
    respiteCareImage: "images/img_1430484_1.png",
    respiteCareHeading: "Personal Care",
    respiteCareDescription:
      "Assistance with daily activities such as bathing, dressing, and grooming, ensuring comfort and dignity for individuals in need of support.",
  },
];

export default function ServicesSection() {
  return (
    <>
      {/* services section */}
      <div className="mt-[78px] flex flex-col items-center">
        <div className="container-xs flex flex-col items-center gap-1 lg:px-5 md:px-5">
          <Text
            size="textmd"
            as="p"
            className="text-[55px] font-medium uppercase text-black-900 lg:text-[46px] md:text-[35px] sm:text-[29px]"
          >
            Services
          </Text>
          <div className="self-stretch">
            <div className="flex flex-col items-center gap-[34px]">
              <Text
                as="p"
                className="w-[56%] text-center text-[18px] font-normal leading-[170%] text-gray-600 lg:w-full lg:text-[15px] md:w-full"
              >
                Personalised support for individuals with dementia, focusing on safety, comfort, and emotional
                well-being in familiar surroundings.
              </Text>
              <div className="flex gap-[30px] self-stretch md:flex-col">
                <Suspense fallback={<div>Loading feed...</div>}>
                  {careServicesList.map((d, index) => (
                    <LearningDisabilityCareComponent
                      {...d}
                      key={"learningList" + index}
                      className="rounded-[10px] bg-white-a700"
                    />
                  ))}
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
