import { Helmet } from "react-helmet";
import { Text } from "../../components";
import Header from "../../components/Header";
import React from "react";
import Footer from "components/Footer";

export default function Page3Page() {
  return (
    <>
      <Helmet>
        <title>Thank You for Registering -  Blue Chip Tech</title>
        <meta
          name="description"
          content="Thank you for your interest in joining  Blue Chip Tech. We're excited to have you on board and will be in touch soon with more details about your journey with us."
        />
      </Helmet>
      <div className="relative w-full bg-white-a700 lg:h-auto md:h-auto">
        <div className=" bg-[url(/public/images/about.png)] bg-cover bg-no-repeat lg:h-auto md:h-auto">
          <div className="flex flex-col items-center gap-[34px] bg-gradient">
            <Header className="mt-[30px] w-[88%] lg:w-full lg:px-5 md:w-full md:px-5 sm:flex-col" />
            <div className="ml-[22px] mr-2 flex h-[438px] w-[86%] items-center justify-center bg-[url(/public/images/img_group_10.svg)] bg-cover bg-no-repeat py-[94px] lg:h-auto lg:w-full lg:px-5 lg:py-8 md:mx-0 md:h-auto md:w-full md:p-5 sm:px-5 sm:py-4">
              <div className="container-xs mb-2.5 flex justify-center px-14 md:px-5 sm:px-4">
                <div className="flex w-full flex-col items-center gap-[26px]">
                  <Text
                    size="textmd"
                    as="p"
                    className="text-[55px] font-medium uppercase text-black-900 lg:text-[46px] md:text-[35px] sm:text-[29px]"
                  >
                    Your journey with us has officially started!
                  </Text>
                  <Text
                    size="textlg"
                    as="p"
                    className="self-stretch text-center text-[22px] font-normal uppercase leading-[180%] text-gray-600 lg:text-[18px]"
                  >
                    Thank you for your interest! We’ll be in touch shortly with
                    more details. We’re excited to explore this opportunity with
                    you!
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
