import { Link, useLocation } from "react-router-dom";
import { Img, Text } from "./..";
import React from "react";

interface Props {
  className?: string;
}

const footerLinks = [
  { id: 1, name: "About Us", path: "/about-us" },
  { id: 2, name: "Services", path: "/service" },
  { id: 3, name: "Contact Us", path: "/contact-us" },
];

export default function Footer({ ...props }: Props) {
  const location = useLocation();

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer
      {...props}
      className={`${props.className} flex justify-center items-end py-10 sm:py-4 bg-black-900`}
    >
      <div className="container-xs mt-[68px] flex  px-3 lg:px-5 md:px-5">
        <div className="w-[95%] sm:w-full lg:w-full md:w-full">
          <div className="mr-7 flex items-start justify-between gap-5 md:mr-0 sm:flex-col">
            <ul className="flex flex-col items-start ">
              <li style={{ marginBottom: "20px" }}>
                <Text
                  size="texts"
                  as="p"
                  className="text-[24px] font-medium uppercase text-white-a700"
                >
                  Useful Links
                </Text>
              </li>
              {footerLinks.map((link) => (
                <li key={link.id}>
                  <Link
                    onClick={handleClick}
                    to={link.path}
                    style={{ textDecoration: "none" }}
                    className="mt-2 lg:text-[20px]"
                  >
                    <Text
                      size="texts"
                      as="p"
                      className={`text-[24px] font-normal uppercase ${location.pathname === link.path
                        ? "text-[#FFf]"
                        : "text-gray-400"
                        }`}
                    >
                      {link.name}
                    </Text>
                  </Link>
                </li>
              ))}
            </ul>
            <div className="flex w-[58%] sm:w-full items-start justify-between gap-5 sm:flex-col">
              <div className="flex flex-col">
                <div className="flex flex-col items-start gap-9 self-stretch">
                  <Text
                    size="texts"
                    as="p"
                    className="text-[24px] font-medium uppercase text-white-a700 lg:text-[20px]"
                  >
                    Contact Us
                  </Text>
                  <Text
                    as="p"
                    className="text-[18px] font-normal text-gray-400"
                  >
                    Address: 14 Douglas Drive, Wantage, Oxfordshire, England, OX12 0GL
                  </Text>
                  <Text
                    as="p"
                    className="text-[18px] font-normal text-gray-400"
                  >
                    Phone: 0186 570 4195
                  </Text>
                  <Text
                as="p"
                className="text-[18px] font-normal text-gray-400 lg:text-[15px]"
              >
                {process.env.REACT_APP_PUBLIC_EMAIL_ADDRESS ? (
                  <div>
                    <div>Email: </div>
                    <span>{process.env.REACT_APP_PUBLIC_EMAIL_ADDRESS}</span>
                  </div>
                ) : (
                  ""
                )}
              </Text>
                </div>
                {/* <Text
                  as="p"
                  className="text-[18px] font-normal text-gray-400 lg:text-[15px]"
                >
                  {process.env.REACT_APP_PUBLIC_CRN ? (
                    <div >
                      <div>CRN: </div>
                      <span>{process.env.REACT_APP_PUBLIC_CRN}</span>
                    </div>
                  ) : (
                    ""
                  )}
                </Text> */}
              </div>
{/*               <Text
                as="p"
                className="text-[18px] font-normal text-gray-400 lg:text-[15px]"
              >
                {process.env.REACT_APP_PUBLIC_EMAIL_ADDRESS ? (
                  <div>
                    <div>Email: </div>
                    <span>{process.env.REACT_APP_PUBLIC_EMAIL_ADDRESS}</span>
                  </div>
                ) : (
                  ""
                )}
              </Text> */}
              <div className="flex flex-col items-start gap-9">
                <Text
                  size="texts"
                  as="p"
                  className="text-[24px] font-medium uppercase text-white-a700 lg:text-[20px]"
                >
                  Follow Us
                </Text>
                <ul className="flex flex-col items-start ">
                  <li className="mb-2">
                    <a
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 10, }}
                      href={`${process.env.REACT_APP_PUBLIC_INSTAGRAM_URL}`}
                      target="_blank"
                      className="lg:text-[15px]"
                      rel="noreferrer"
                    >

                      <Img
                        src="images/insta.svg"
                        alt="Man Expression"
                        height={24}
                        width={24}
                      />
                      <Text
                        as="p"
                        className="text-[18px] font-normal text-gray-400"
                      >
                        Instagram
                      </Text>
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      style={{
                        display: 'flex', alignItems: 'center', gap: 10, justifyContent: 'space-between'
                      }}
                      href={`${process.env.REACT_APP_PUBLIC_TIK_TOK_URL}`}
                      target="_blank"
                      rel="noreferrer"
                      className="lg:text-[15px]"
                    >

                      <Img
                        src="images/tiktok.svg"
                        alt="Man Expression"
                        height={24}
                        width={24}
                      />
                      <Text
                        as="p"
                        className="text-[18px] font-normal text-gray-400"
                      >
                        TikTok
                      </Text>
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      style={{ display: 'flex', alignItems: 'center', gap: 10, justifyContent: 'space-between', }}
                      href={`${process.env.REACT_APP_PUBLIC_TWITTER_URL}`}
                      target="_blank"
                      rel="noreferrer"
                      className="lg:text-[15px]"
                    >

                      <Img
                        src="images/twitter.svg"
                        alt="Man Expression"
                        height={24}
                        width={24}
                      />
                      <Text
                        as="p"
                        className="text-[18px] font-normal text-gray-400"
                      >
                        Twitter
                      </Text>
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      style={{ display: 'flex', alignItems: 'center', gap: 10, justifyContent: 'space-between', }}
                      href={`${process.env.REACT_APP_PUBLIC_FACEBOOK_URL}`}
                      target="_blank"
                      rel="noreferrer"
                      className="lg:text-[15px]"
                    >

                      <Img
                        src="images/faceb.svg"
                        alt="Man Expression"
                        height={24}
                        width={24}
                      />
                      <Text
                        as="p"
                        className="text-[18px] font-normal text-gray-400"
                      >
                        Facebook
                      </Text>
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      style={{ display: 'flex', alignItems: 'center', gap: 10, justifyContent: 'space-between', }}
                      href={`${process.env.REACT_APP_PUBLIC_PINTEREST_URL}`}
                      target="_blank"
                      rel="noreferrer"
                      className="lg:text-[15px]"
                    >

                      <Img
                        src="images/pinterest.svg"
                        alt="Man Expression"
                        height={24}
                        width={24}
                      />
                      <Text
                        as="p"
                        className="text-[18px] font-normal text-gray-400"
                      >
                        Pinterest
                      </Text>
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      style={{ display: 'flex', alignItems: 'center', gap: 10, justifyContent: 'space-between', }}
                      href={`${process.env.REACT_APP_PUBLIC_YOUTUBE_URL}`}
                      target="_blank"
                      rel="noreferrer"
                      className="lg:text-[15px]"
                    >

                      <Img
                        src="images/youtube.svg"
                        alt="Man Expression"
                        height={24}
                        width={24}
                      />
                      <Text
                        as="p"
                        className="text-[18px] font-normal text-gray-400"
                      >
                        Youtube
                      </Text>
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      style={{ display: 'flex', alignItems: 'center', gap: 10, justifyContent: 'space-between', }}
                      href={`${process.env.REACT_APP_PUBLIC_LINKEDIN_URL}`}
                      target="_blank"
                      rel="noreferrer"
                      className="lg:text-[15px]"
                    >

                      <Img
                        src="images/linkedin-new.svg"
                        alt="Man Expression"
                        height={24}
                        width={24}
                      />
                      <Text
                        as="p"
                        className="text-[18px] font-normal text-gray-400"
                      >
                        LinkedIn
                      </Text>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-[98px] h-px bg-gray-200_01" />
          <div className="mt-[52px] flex justify-between gap-5 sm:flex-col">
            <Text
              as="p"
              className="text-[18px] font-normal text-white-a700 lg:text-[15px]"
            >
              Copyright © {(new Date().getFullYear())}. All right reserved
            </Text>
            <ul className="flex flex-wrap gap-2.5">
              <li>
                <Link
                  onClick={handleClick}
                  to="/terms-and-conditions"
                  className="lg:text-[15px]"
                >
                  <Text
                    as="p"
                    className="text-[18px] font-normal text-white-a700"
                  >
                    Terms and Conditions
                  </Text>
                </Link>
              </li>
              <li>
                <Text
                  as="p"
                  className="text-[18px] font-normal text-white-a700"
                >
                  |
                </Text>
              </li>
              <li>
                <Link
                  onClick={handleClick}
                  to="/privacy-policy"
                  className="lg:text-[15px]"
                >
                  <Text
                    as="p"
                    className="text-[18px] font-normal text-white-a700"
                  >
                    Privacy Policy
                  </Text>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer >
  );
}
