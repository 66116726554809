import CareServicesOverview from "../../components/CareServicesOverview";
import React, { Suspense } from "react";

const careOptionsList = [
  {
    img1: "images/s1.png",
    learningDisabilityTitle: "Learning Disability Care",
    learningDisabilityDescription:
      "Personalised care plans that focus on promoting independence and enhancing the quality of life for individuals with learning disabilities.",
    img2: "images/s2.png",
    endOfLifeTitle: "End-of-Life Care",
    endOfLifeDescription:
      "Compassionate care that provides comfort, dignity, and support to individuals and their families during the final stages of life.",
  },
  {
    img1: "images/s3.png",
    learningDisabilityTitle: "Live-in Care",
    learningDisabilityDescription:
      "Around-the-clock care delivered in the comfort of your own home, offering continuous support for daily living activities.",
    img2: "images/s4.png",
    endOfLifeTitle: "Respite Care",
    endOfLifeDescription:
      "Temporary care services that offer relief for family caregivers, giving them time to rest while ensuring their loved ones receive quality care.",
  },
  {
    img1: "images/s5.png",
    learningDisabilityTitle: "Overnight Care",
    learningDisabilityDescription:
      "Support during the night to ensure safety, provide assistance, and address any needs that may arise during nighttime hours.",
    img2: "images/s6.png",
    endOfLifeTitle: "Personal Care",
    endOfLifeDescription:
      "Assistance with daily activities such as bathing, dressing, and grooming, ensuring comfort and dignity for individuals in need of support.",
  },
];

export default function ServiceCareSection() {
  return (
    <>
      {/* service care section */}
      <div className="flex flex-col items-center px-14 md:px-5 sm:px-4">
        <div className="flex w-[68%] flex-col gap-[78px] md:w-full">
          <Suspense fallback={<div>Loading feed...</div>}>
            {careOptionsList.map((d, index) => (
              <CareServicesOverview {...d} key={"learningList" + index} />
            ))}
          </Suspense>
        </div>
      </div>
    </>
  );
}
