// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alice-carousel__dots{
  position: relative;
  bottom: 120px;
}

.alice-carousel__dots-item.__active.__custom .alice-carousel__dots-item{
  background-color: #020000;
}

.alice-carousel__dots-item:not(.__custom) {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  background-color: white;
}

.splide__arrow{
background-color: rgb(233, 226, 226);
opacity: 1;
border: 1px solid #f64f58;
}

.splide__arrow--prev {
  left: -1em;
}
.splide__arrow--next {
  right: -1em;
}

.splide__arrow svg {
  fill: #f64f58; 
}

`, "",{"version":3,"sources":["webpack://./src/pages/Home1/OurWorkShowcaseSection.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;AACA,oCAAoC;AACpC,UAAU;AACV,yBAAyB;AACzB;;AAEA;EACE,UAAU;AACZ;AACA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;AACf","sourcesContent":[".alice-carousel__dots{\n  position: relative;\n  bottom: 120px;\n}\n\n.alice-carousel__dots-item.__active.__custom .alice-carousel__dots-item{\n  background-color: #020000;\n}\n\n.alice-carousel__dots-item:not(.__custom) {\n  width: 20px;\n  height: 20px;\n  margin-right: 5px;\n  background-color: white;\n}\n\n.splide__arrow{\nbackground-color: rgb(233, 226, 226);\nopacity: 1;\nborder: 1px solid #f64f58;\n}\n\n.splide__arrow--prev {\n  left: -1em;\n}\n.splide__arrow--next {\n  right: -1em;\n}\n\n.splide__arrow svg {\n  fill: #f64f58; \n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
