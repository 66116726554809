import { Text, Img } from "./..";
import React from "react";

interface Props {
  className?: string;
  creditCardImage?: string;
  headingText?: React.ReactNode;
}

export default function UnwaveringCareSection({
  creditCardImage = "images/img_credit_card.svg",
  headingText = "Unwavering Care",
  ...props
}: Props) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col items-center justify-center w-[32%] md:w-full gap-[42px] px-14 py-[106px] lg:py-8 md:p-5 sm:p-4 bg-white-a700 rounded-[10px]`}
    >
      <Img src={creditCardImage} alt="Credit Card Image" className="h-[80px] w-[34%] object-contain" />
      <Text size="texts" as="p" className="text-[24px] font-medium uppercase text-black-900">
        {headingText}
      </Text>
    </div>
  );
}
