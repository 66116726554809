import React from "react";

const shapes = {
  round: "rounded-[10px]",
} as const;
const variants = {
  outline: {
    black_900: "border-black-900 border-2 border-solid text-black-900",
  },
  fill: {
    red_A200: "bg-red-a200 shadow-sm text-white-a700",
  },
} as const;
const sizes = {
  md: "h-[80px] px-[34px] text-[24px]",
  xs: "h-[56px] px-[34px] text-[18px]",
  sm: "h-[60px] px-[34px] text-[24px]",
} as const;

type ButtonProps = Omit<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  "onClick"
> &
  Partial<{
    className: string;
    leftIcon: React.ReactNode;
    rightIcon: React.ReactNode;
    onClick: () => void;
    shape: keyof typeof shapes;
    variant: keyof typeof variants | null;
    size: keyof typeof sizes;
    color: string;
  }>;
const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant = "outline",
  size = "sm",
  color = "black_900",
  ...restProps
}) => {
  return (
    <button
      className={`${className} flex flex-row items-center justify-center text-center cursor-pointer whitespace-nowrap uppercase font-medium rounded-[10px] ${shape && shapes[shape]} ${size && sizes[size]} ${variant && variants[variant]?.[color as keyof (typeof variants)[typeof variant]]}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

export { Button };
