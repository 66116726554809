import {
  Text,
  Heading,
  Img,
  //  Slider
} from "../../components";
import React, { useState } from "react";
// import AliceCarousel, { EventObject } from "react-alice-carousel";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "./OurWorkShowcaseSection.css";

export default function OurServicesSection() {
  // const [sliderState, setSliderState] = React.useState(0);
  // const sliderRef = React.useRef<AliceCarousel>(null);

  const images = [
    "images/slider-1.png",
    "images/slider-2.png",
    "images/slider-3.png",
    "images/slider-4.png",
  ];

  // State to track the current image
  const [currentImage, setCurrentImage] = useState(images[0]);

  // Function to handle slide change
  const handleSlideChange = (splide) => {
    const currentIndex = splide.index;
    setCurrentImage(images[currentIndex]);
  };

  return (
    <>
      {/* our services section */}
      <div className="mt-[188px] flex flex-col items-center">
        <div className="container-xs flex flex-col gap-[156px] lg:gap-[117px] lg:px-5 md:gap-[117px] md:px-5 sm:gap-[78px]">
          <div className="flex flex-col gap-[74px] lg:gap-[74px] md:gap-[55px] sm:gap-[37px]">
            <div className="mx-[198px] flex flex-col items-center gap-2 md:mx-0">
              <Text
                size="textmd"
                as="p"
                className="text-[55px] font-medium uppercase text-black-900 lg:text-[46px] md:text-[35px] sm:text-[29px]"
              >
                The Heart of our service
              </Text>
              <Text
                as="p"
                className="self-stretch text-center text-[18px] font-normal leading-[170%] text-gray-600 lg:text-[15px]"
              >
                <span>
                  Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis
                  ullamco cillum dolor. Voluptate exercitation incididunt
                  aliquip deserunt&nbsp;
                </span>
                <span className="text-[20px]">
                  magna nulla duis ullamco cillum dolor.&nbsp;
                </span>
              </Text>
            </div>
            <div className="flex flex-col gap-[100px] lg:gap-[100px] md:gap-[75px] sm:gap-[50px]">
              <div className="flex items-start md:flex-col">
                <div className="flex w-full items-start gap-[30px] sm:flex-col">
                  <Heading
                    size="text6xl"
                    as="h1"
                    className="text-shadow-ts mt-2.5 flex h-[70px] w-[70px] items-center justify-center rounded-[10px] bg-red-a200 text-center text-[42px] font-medium uppercase text-white-a700 lg:text-[35px] md:text-[26px]"
                  >
                    01
                  </Heading>
                  <div className="flex flex-1 flex-col items-start gap-4 self-center sm:self-stretch">
                    <Text
                      size="texts"
                      as="p"
                      className="text-[24px] font-medium uppercase text-black-900 lg:text-[20px]"
                    >
                      Skilled and Compassionate Team
                    </Text>
                    <Text
                      as="p"
                      className="w-[86%] text-[18px] font-normal leading-[170%] text-gray-600 lg:w-full lg:text-[15px] md:w-full"
                    >
                      Our team combines advanced training with core values of
                      empathy and respect, allowing them to provide care that
                      truly connects with and supports each individual.
                    </Text>
                  </div>
                </div>
                <div className="w-full self-center bg-gray-300_01">
                  <Img
                    src="images/img_2148940078_1.png"
                    alt="Image Element"
                    className="h-[260px] w-full object-cover lg:h-auto md:h-auto"
                  />
                </div>
              </div>
              <div className="flex items-start md:flex-col">
                <div className="w-full self-center bg-gray-300_01 md:order-last">
                  <Img
                    src="images/img_2148900575_1.png"
                    alt="Dining Image"
                    className="h-[260px] w-full object-cover lg:h-auto md:h-auto"
                  />
                </div>
                <div className="flex w-full items-start gap-[30px] sm:flex-col justify-center md:justify-start">
                  <Heading
                    size="text6xl"
                    as="h2"
                    className="text-shadow-ts mt-2.5 flex h-[70px] w-[70px] items-center justify-center rounded-[10px] bg-red-a200 text-center text-[42px] font-medium uppercase text-white-a700 lg:text-[35px] md:text-[26px]"
                  >
                    02
                  </Heading>
                  <div className="flex w-[76%] flex-col items-start gap-4 self-center sm:w-full">
                    <Text
                      size="texts"
                      as="p"
                      className="text-[24px] font-medium uppercase text-black-900 lg:text-[20px]"
                    >
                      Quality Dining Experience
                    </Text>
                    <Text
                      as="p"
                      className="w-full text-[18px] font-normal leading-[170%] text-gray-600 lg:text-[15px]"
                    >
                      We see meals as both a pleasure and a time to connect,
                      offering freshly prepared, nutritionally balanced dishes
                      for an enjoyable, social dining experience.
                    </Text>
                  </div>
                </div>
              </div>
              <div className="flex items-start md:flex-col">
                <div className="flex w-full items-start gap-[30px] sm:flex-col">
                  <Heading
                    size="text6xl"
                    as="h3"
                    className="text-shadow-ts mt-2.5 flex h-[70px] w-[70px] items-center justify-center rounded-[10px] bg-red-a200 text-center text-[42px] font-medium uppercase text-white-a700 lg:text-[35px] md:text-[26px]"
                  >
                    03
                  </Heading>
                  <div className="flex flex-1 flex-col items-start gap-4 self-center sm:self-stretch">
                    <Text
                      size="texts"
                      as="p"
                      className="text-[24px] font-medium uppercase text-black-900 lg:text-[20px]"
                    >
                      Person-Centered Approach
                    </Text>
                    <Text
                      as="p"
                      className="w-[86%] text-[18px] font-normal leading-[170%] text-gray-600 lg:w-full lg:text-[15px] md:w-full"
                    >
                      By understanding each person’s unique history,
                      preferences, and needs, we create personalised care plans
                      that ensure individuals remain at the center of their own
                      care journey.
                    </Text>
                  </div>
                </div>
                <div className="w-full self-center bg-gray-300_01">
                  <Img
                    src="images/img_2148757699_1.png"
                    alt="Approach Image"
                    className="h-[260px] w-full object-cover lg:h-auto md:h-auto"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-start md:flex-col md:order-start">
            <div className="mt-2 flex w-[56%] flex-col items-center gap-12 self-center px-3 md:w-full">
              {/* <div className="flex rounded-[20px] bg-gray-300_01">
                <Img
                  src="images/img_2148934322_1.png"
                  alt="Slider Image"
                  className="h-[542px] w-full rounded-[20px] object-cover lg:h-auto md:h-auto"
                />
              </div> */}
              {/* <div className="flex justify-center gap-[37px] self-stretch sm:flex-col">
                <div className="mx-auto flex w-[74%] sm:w-full">
                  <Slider
                    autoPlay
                    autoPlayInterval={2000}
                    responsive={{
                      "0": { items: 1 },
                      "551": { items: 1 },
                      "1051": { items: 1 },
                      "1441": { items: 1 },
                    }}
                    disableDotsControls
                    activeIndex={sliderState}
                    onSlideChanged={(e: EventObject) => {
                      setSliderState(e?.item);
                    }}
                    ref={sliderRef}
                    items={[...Array(3)].map(() => (
                      <React.Fragment key={Math.random()}>
                        <div className="flex sm:flex-col">
                          <Img
                            src="images/img_rectangle_39.png"
                            alt="Slider Image One"
                            className="h-[142px] w-[16%] rounded-br-[20px] rounded-tr-[20px] object-contain opacity-50 sm:w-full"
                          />
                          <Img
                            src="images/img_rectangle_40.png"
                            alt="Slider Image Two"
                            className="h-[142px] w-[142px] rounded-[20px] object-cover sm:w-full"
                          />
                          <Img
                            src="images/img_rectangle_34.png"
                            alt="Slider Image Three"
                            className="h-[142px] w-[142px] rounded-[20px] object-cover opacity-50 sm:w-full"
                          />
                        </div>
                      </React.Fragment>
                    ))}
                  />
                </div>
                <div className="flex flex-col items-center rounded-br-[30px] rounded-tr-[30px] bg-white-a700 px-[34px] py-[46px] shadow-xs md:py-5 sm:p-4">
                  <Img
                    src="images/img_chevron_right.svg"
                    alt="Chevron Image"
                    className="h-[50px]"
                  />
                </div>
              </div> */}
              <div style={{ marginBottom: "1rem" }}>
                <img
                  src={currentImage}
                  alt="Current Slide"
                  className="h-[566px] w-full rounded-[20px] object-cover lg:h-auto md:h-auto"
                />
              </div>

              {/* Splide slider */}
              <Splide
                options={{
                  type: "loop",
                  perPage: 3,
                  focus: "center",
                  autoplay: false,
                  interval: 2000,
                  gap: "0rem",
                  pagination: false,
                  arrows: true, // Display next/previous buttons
                }}
                onMove={handleSlideChange} // Event listener to handle slide change
                className="w-full"
              >
                {images.map((image, index) => (
                  <SplideSlide key={index} className="flex justify-center">
                    <Img
                      src={image}
                      alt={`Slide ${index + 1}`}
                      className={`h-[142px] w-[142px] rounded-md object-cover ${
                        currentImage === image ? "opacity-100" : "opacity-50"
                      }`}
                    />
                  </SplideSlide>
                ))}
              </Splide>
            </div>
            <div className="flex w-[46%] flex-col items-center md:w-full">
              <div className="flex flex-col items-start self-stretch">
                <Heading
                  size="headingxs"
                  as="h4"
                  className="text-[28px] font-bold text-red-a200 lg:text-[23px] md:text-[22px]"
                >
                  Our Story
                </Heading>
                <Heading
                  size="text5xl"
                  as="h5"
                  className="mt-2 w-[94%] text-[39px] font-medium uppercase leading-[57px] text-black-900 lg:w-full lg:text-[33px] md:w-full md:text-[33px] sm:text-[31px]"
                >
                  Our Journey of Compassionate Care
                </Heading>
                <Text
                  size="textmd"
                  as="p"
                  className="mt-[34px] w-full text-[21px] font-normal leading-[170%] text-gray-600 lg:text-[17px]"
                >
                  Blue Chip Tech has been dedicated to creating
                  positive change in the lives of those we care for.
                </Text>
              </div>
              <Text
                size="textmd"
                as="p"
                className="mt-6 w-[96%] text-[21px] font-normal leading-[170%] text-gray-600 lg:w-full lg:text-[17px] md:w-full"
              >
                With compassion and respect at our core, we’re committed to
                understanding each individual&#39;s needs and crafting care that
                enhances independence, well-being, and dignity. Our skilled team
                goes beyond support—they empower lives, foster confidence, and
                improve quality of life through personalised attention and
                professionalism.
              </Text>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
