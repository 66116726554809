// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/fonts/OswaldMedium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/public/fonts/OswaldRegular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("/public/fonts/OswaldBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("/public/fonts/LatoRegular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-display: swap;
  font-style: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  font-family: "Oswald";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  font-family: "Oswald";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  font-family: "Oswald";
  font-weight: 700;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
  font-family: "Lato";
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/styles/font.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,4CAA0C;EAC1C,qBAAqB;EACrB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,4CAA2C;EAC3C,qBAAqB;EACrB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,4CAAwC;EACxC,qBAAqB;EACrB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,4CAAyC;EACzC,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":["@font-face {\n  font-display: swap;\n  font-style: normal;\n  src: url(\"/public/fonts/OswaldMedium.ttf\");\n  font-family: \"Oswald\";\n  font-weight: 500;\n}\n@font-face {\n  font-display: swap;\n  font-style: normal;\n  src: url(\"/public/fonts/OswaldRegular.ttf\");\n  font-family: \"Oswald\";\n  font-weight: 400;\n}\n@font-face {\n  font-display: swap;\n  font-style: normal;\n  src: url(\"/public/fonts/OswaldBold.ttf\");\n  font-family: \"Oswald\";\n  font-weight: 700;\n}\n@font-face {\n  font-display: swap;\n  font-style: normal;\n  src: url(\"/public/fonts/LatoRegular.ttf\");\n  font-family: \"Lato\";\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
