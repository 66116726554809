import { useState } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { CONTACT_US_API_HEADER_KEY, CONTACT_US_API_URL } from "config";

interface FormData {
  name: string;
  email: string;
  phoneNumber: string;
  query: string;
}

interface ValidationErrors {
  name?: string;
  email?: string;
  phoneNumber?: string;
  query?: string;
}

export const useContactUs = () => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    phoneNumber: "",
    query: "",
  });
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateForm = () => {
    const errors: ValidationErrors = {};
    const phonePattern = /^\+44[0-9]{10}$/;

    if (!formData.name) errors.name = "Name is required";
    if (!formData.email) errors.email = "Email is required";
    if (!formData.phoneNumber) {
      errors.phoneNumber = "Phone number is required";
    } else if (!phonePattern.test(formData.phoneNumber)) {
      errors.phoneNumber = "Phone number must be in the format +44 followed by 10 digits";
    }
    if (!formData.query) errors.query = "Query is required";
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const submitForm = async () => {
    if (!validateForm()) return;

    try {
      await axios.post("/enquiries", formData, {
        headers: {
          "x-api-key": CONTACT_US_API_HEADER_KEY,
        },
        baseURL: CONTACT_US_API_URL,
      });

      setFormData({
        name: "",
        email: "",
        phoneNumber: "",
        query: "",
      });
      setValidationErrors({});
      enqueueSnackbar("Message sent successfully!", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Error submitting form. Please try again.", { variant: "error" });
      console.error("Error submitting form:", error);
    }
  };

  return { formData, handleChange, submitForm, validationErrors };
};
