import React from "react";

const shapes = {
  square: "rounded-[0px]",
} as const;

const variants = {
  fill: {
    gray_100_02: "bg-gray-100_02 text-gray-600",
  },
} as const;

const sizes = {
  xs: "h-[100px] px-[38px] text-[24px]",
} as const;

type InputProps = Omit<
  React.ComponentPropsWithoutRef<"input">,
  "prefix" | "size"
> &
  Partial<{
    label: string;
    prefix: React.ReactNode;
    suffix: React.ReactNode;
    shape: keyof typeof shapes;
    variant: keyof typeof variants | null;
    size: keyof typeof sizes;
    color: string;
    errorMessage?: string; // Add this line for validation message
  }>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className = "",
      name = "",
      placeholder = "",
      type = "text",
      label = "",
      prefix,
      suffix,
      onChange,
      shape,
      variant = "fill",
      size = "xs",
      color = "gray_100_02",
      errorMessage, // Include the error message prop
      ...restProps
    },
    ref
  ) => {
    return (
      <div className="w-full">
        <label
          className={`${className} flex items-center justify-start gap-[10px] px-4 py-2 text-gray-600 uppercase text-[24px] bg-gray-100_02 ${
            shape && shapes[shape]
          } ${
            variant &&
            (variants[variant]?.[
              color as keyof (typeof variants)[typeof variant]
            ] ||
              variants[variant])
          } ${size && sizes[size]}`}
        >
          {!!prefix && <span className="mr-2">{prefix}</span>}
          <input
            ref={ref}
            type={type}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            className="bg-transparent outline-none flex-1 border-0 focus:border-0 placeholder-[#6F6D71] placeholder-italic text-[24px]"
            {...restProps}
          />
          {!!suffix && <span className="ml-2">{suffix}</span>}
        </label>
        <div>
          {errorMessage && ( 
            <span className="text-red-500 text-sm">{errorMessage}</span>
          )}
        </div>
      </div>
    );
  }
);

export { Input };
