import { Img, Text, Heading } from "../../components";
import React from "react";

export default function AboutUsSection() {
  return (
    <>
      {/* about us section */}
      <div className="mt-32 flex flex-col items-center">
        <div className="container-xs flex flex-col gap-[116px] lg:gap-[116px] lg:px-5 md:gap-[87px] md:px-5 sm:gap-[58px]">
          <div className="flex items-start justify-between gap-5 md:flex-col">
            <div className="relative h-[354px] w-[38%] content-center self-center rounded-[20px] lg:h-auto md:h-auto md:w-full">
              <Img
                src="images/about-1.png"
                alt="Face Recognition"
                className="mx-auto h-[354px] w-full flex-1 rounded-[20px] object-cover"
              />
            </div>
            <div className="flex w-[56%] flex-col items-start gap-5 md:w-full">
              <Heading
                size="text6xl"
                as="h1"
                className="text-[42px] font-medium text-black-900 lg:text-[35px] md:text-[26px]"
              >
                Our Vision & Mission
              </Heading>
              <Text
                size="textlg"
                as="p"
                className="w-full text-[22px] font-normal leading-[170%] text-gray-600 lg:text-[18px]"
              >
                Blue Chip Tech is grounded in a commitment to dignity, independence, and personalised care. Our vision
                is to build a community of support that values each individual, offering respectful, tailored care for
                every need. Our mission is clear: to deliver compassionate, high-quality services that enhance
                well-being, instill confidence, and ensure a fulfilling life at every stage.
              </Text>
            </div>
          </div>
          <div className="flex items-start justify-between gap-5 md:flex-col transform rotate-180">
            <div className="flex w-[56%] flex-col items-end gap-5 md:w-full order-last lg:item-end">
              <Heading
                size="text6xl"
                as="h2"
                className="text-[42px] font-medium text-black-900 lg:text-[35px] md:text-[26px] transform rotate-180 order-last md:items-start"
              >
                Our History
              </Heading>
              <Text
                size="textlg"
                as="p"
                className="w-full text-[22px] font-normal leading-[170%] text-gray-600 lg:text-[18px] md:text-left transform rotate-180"
              >
                Since our founding,  Blue Chip Tech has grown through a steadfast commitment to quality and community
                trust. From our early days, we have focused on setting and upholding high standards, establishing
                ourselves as a reliable, compassionate care provider. This growth is a testament to our professionalism,
                dedication, and continuous evolution to meet client needs.
              </Text>
            </div>
            <div className="flex self-center rounded-[20px] bg-gray-300_01 shadow-xs transform rotate-180 md:order-last">
              <Img
                src="images/img_close_up_man_ex.png"
                alt="Man Expression"
                className="h-[354px] w-full rounded-[20px] object-cover lg:h-auto md:h-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
