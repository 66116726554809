import { Text } from "../../components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import AboutUsSection from "./AboutUsSection";
import AboutUsSection1 from "./AboutUsSection1";
import OurValuesSection from "./OurValuesSection";
import React from "react";

export default function AboutUsPage() {
  return (
    <>
      <div className="w-full bg-white-a700">
        <div className="h-[560px]  bg-cover  bg-[url(/public/images/banner.png)] bg-no-repeat lg:h-auto md:h-auto">
          <div className="flex flex-col items-center gap-[34px] bg-gradient">
            <Header className="mt-[30px] w-[88%] lg:w-full lg:px-5 md:w-full md:px-5 sm:flex-col" />
            <div className="ml-[22px] mr-2 flex h-[438px] w-[86%] items-center justify-center  lg:h-auto lg:w-full lg:px-5 lg:py-8 md:mx-0 md:h-auto md:w-full md:p-5 sm:px-5 sm:py-4">
              <div className="container-xs mb-2.5 flex justify-center px-14 md:px-5 sm:px-4">
                <div className="flex w-full flex-col items-center gap-[26px]">
                  <Text
                    size="textmd"
                    as="p"
                    className="text-[55px] font-medium uppercase text-[#010101] lg:text-[46px] md:text-[35px] sm:text-[29px]"
                  >
                    About Us
                  </Text>
                  <Text
                    size="textlg"
                    as="p"
                    className="self-stretch text-center text-lg font-normal uppercase leading-[180%] text-gray-600"
                  >
                    At  Blue Chip Tech, we believe that every individual
                    deserves care that respects their dignity, promotes
                    independence, and prioritizes safety. Founded on values of
                    integrity and excellence, we strive to create a warm,
                    supportive environment for our clients and our team.
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* about us section */}
        <AboutUsSection />

        {/* about us section */}
        <AboutUsSection1 />

        {/* our values section */}
        <OurValuesSection />
        <Footer className="mt-[150px]" />
      </div>
    </>
  );
}
