import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { SnackbarProvider } from "notistack";
import "./styles/tailwind.css";
import "./styles/index.css";
import "./styles/font.css";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <SnackbarProvider
    maxSnack={3}
    anchorOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
  >
    <App />
  </SnackbarProvider>
);
