import { Text } from "../../components";
import Header from "../../components/Header";
import ContactUsSection from "./ContactUsSection";
import React from "react";
import Footer from "components/Footer";

export default function ContactUsPage() {
  return (
    <>

      <div className="flex w-full flex-col gap-[84px] lg:gap-[84px] md:gap-[63px] sm:gap-[42px]">
        <div className="flex flex-col gap-[90px] lg:gap-[90px] md:gap-[67px] sm:gap-[45px]">
          <div className="flex flex-col items-end h-[560px] bg-[url(/public/images/main-banner.png)] bg-cover bg-no-repeat lg:h-auto md:h-auto">
            <Header className="relative z-[1] self-stretch px-[120px] py-[26px] lg:px-8 md:px-5 sm:flex-col sm:p-4" />
            <div className="relative  mb-[42px] mt-[-122px] flex h-[570px] w-[92%] items-end  py-[140px] lg:h-auto lg:w-full lg:px-5 lg:py-8 md:h-auto md:w-full md:p-5 sm:px-5 sm:py-4">
              <div className="container-xs mt-[98px] flex justify-center px-14 md:px-5 sm:px-4 ">
                <div className="flex flex-col items-center gap-7 px-14 md:px-5 sm:px-4">
                  <Text
                    size="textmd"
                    as="p"
                    className="text-[55px] font-medium uppercase text-[#010101] lg:text-[46px] md:text-[35px] sm:text-[29px]"
                  >
                    Write Us a Message
                  </Text>
                  <Text
                    as="p"
                    className="text-[18px] font-normal text-gray-600 lg:text-[15px]"
                  >
                    We are always available to help you with any queries you may
                    have. Please feel free to contact us.
                  </Text>
                </div>
              </div>
            </div>
          </div>
          <ContactUsSection />
        </div>
        <Footer />
      </div>
    </>
  );
}
