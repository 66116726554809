import { Text, Img } from "./..";
import React from "react";

interface Props {
  className?: string;
  settingsImage?: string;
  headingText?: React.ReactNode;
  descriptionText?: React.ReactNode;
  respiteCareImage?: string;
  respiteCareHeading?: React.ReactNode;
  respiteCareDescription?: React.ReactNode;
}

export default function LearningDisabilityCareComponent({
  settingsImage = "images/img_settings.svg",
  headingText = "Learning Disability Care",
  descriptionText = "Personalised care plans that focus on promoting independence and enhancing the quality of life for individuals with learning disabilities.",
  respiteCareImage = "images/img_healthcare_1_1.svg",
  respiteCareHeading = "Respite Care",
  respiteCareDescription = "Temporary care services that offer relief for family caregivers, giving them time to rest while ensuring their loved ones receive quality care.",
  ...props
}: Props) {
  return (
    <div {...props} className={`${props.className} flex flex-col items-center w-[32%] md:w-full`}>
      <div className="mt-[38px] flex flex-col items-center gap-7 self-stretch">
        <Img src={settingsImage} alt="Settings Image" className="h-[100px] w-[100px]" />
        <Text size="texts" as="p" className="text-[24px] font-medium uppercase text-black-900">
          {headingText}
        </Text>
        <Text as="p" className="self-stretch text-center text-[18px] font-normal leading-[170%] text-gray-600">
          {descriptionText}
        </Text>
        <div className="flex flex-col items-center gap-8 self-stretch rounded-[10px] bg-white-a700 px-5 py-[38px] sm:py-5">
          <Img src={respiteCareImage} alt="Healthcare Image" className="h-[100px] w-[100px]" />
          <Text size="texts" as="p" className="text-[24px] font-medium uppercase text-black-900">
            {respiteCareHeading}
          </Text>
          <Text as="p" className="self-stretch text-center text-[18px] font-normal leading-[170%] text-gray-600">
            {respiteCareDescription}
          </Text>
        </div>
      </div>
    </div>
  );
}
