import React from "react";
import { useRoutes } from "react-router-dom";
import NotFound from "pages/NotFound";
import Home1 from "pages/Home1";
import AboutUs from "pages/AboutUs";
import Service from "pages/Service";
import ContactUs from "pages/ContactUs";
import TermsConditionsPage from "pages/TermsConditions";
import Page3 from "pages/Page3";
import CareerPage from "pages/Career";
import SignUpPage from "pages/SignUp";
import PrivacyPolicyPage from "pages/PrivacyPolicy";

const ProjectRoutes = () => {
  let element = useRoutes([
    { path: "/", element: <Home1 /> },
    { path: "*", element: <NotFound /> },
    {
      path: "home1",
      element: <Home1 />,
    },
    {
      path: "about-us",
      element: <AboutUs />,
    },
    {
      path: "service",
      element: <Service />,
    },
    {
      path: "contact-us",
      element: <ContactUs />,
    },
    {
      path: "sign-up",
      element: <SignUpPage />,
    },
    {
      path: "terms-and-conditions",
      element: <TermsConditionsPage />,
    },
    {
      path: "privacy-policy",
      element: <PrivacyPolicyPage />,
    },
    {
      path: "career",
      element: <CareerPage />,
    },
    {
      path: "page3",
      element: <Page3 />,
    },
  ]);

  return element;
};

export default ProjectRoutes;
