import { Button, Text, Img } from "./..";
import React, { useState, useCallback, useMemo, CSSProperties } from "react";
import { Link, useLocation } from "react-router-dom";
import { Drawer, IconButton, List, ListItem, ListItemText, useMediaQuery, Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";

interface Props {
  className?: string;
  logo?: string;
  headerHeight?: CSSProperties["height"];
}

export default function Header({ className = "", logo = "images/site95.png", headerHeight, ...props }: Props) {
  const location = useLocation();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const headerStyle: CSSProperties = useMemo(() => {
    return {
      height: headerHeight,
    };
  }, [headerHeight]);

  const menuItems = [
    { name: "Home", href: "/" },
    { name: "About Us", href: "/about-us" },
    { name: "Services", href: "/service" },
    { name: "Contact Us", href: "/contact-us" },
    { name: "Career", href: "/career" },
  ];

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawerList = (
    <List>
      {menuItems.map((item) => (
        <ListItem key={item.href} component={Link} to={item.href} onClick={toggleDrawer(false)}>
          <ListItemText primary={item.name} />
        </ListItem>
      ))}
      <ListItem component={Link} to="/sign-up" onClick={toggleDrawer(false)}>
        <Button
          shape="round"
          className="sm:min-w-[160px] md:min-w-[30px] self-center rounded-[10px] !border-2 px-6 md:px-[1] sm:px-6 md:text-[16px]"
        >
          Register
        </Button>
      </ListItem>
    </List>
  );

  return (
    <div className={`${className} flex justify-between items-start gap-5`} style={headerStyle}>
      <Box sx={{ display: 'flex', width: { md: 'auto', xs: '100%' }, justifyContent: 'space-between' }}>
        <div className="cursor-pointer">
          <Link to="/">
            <Img
              src={logo}
              alt="Header Logo"
              className="mt-1.5 h-[48px] w-[222px]  object-contain"
            />
          </Link>
        </div>
        {!isMdUp && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        )}
      </Box>
      <header className="flex items-center">
        {isMdUp ? (
          <ul className="flex items-center gap-10 md:gap-4 self-end sm:flex-col sm:self-auto">
            {menuItems.map((item) => (
              <li key={item.href}>
                <Link to={item.href} className="cursor-pointer lg:text-[20px]">
                  <div className="flex flex-col items-start gap-2 sm:gap-3 md:gap-4 lg:gap-2.5">
                    <Text
                      size="texts"
                      as="p"
                      className={`text-[20px] sm:text-[22px] md:text-[16px] lg:text-[20px] font-medium uppercase ${location.pathname === item.href
                        ? "text-red-a200"
                        : "text-black-900"
                        } hover:font-medium hover:text-red-a200`}
                    >
                      {item.name}
                    </Text>
                    {location.pathname === item.href && (
                      <div className="h-[3px] w-[42px] bg-red-a200" />
                    )}
                  </div>
                </Link>
              </li>
            ))}
            <li>
              <Link to="/sign-up">
                <Button
                  shape="round"
                  className="sm:min-w-[160px] md:min-w-[30px] self-center rounded-[10px] !border-2 px-6 md:px-[1] sm:px-6 md:text-[16px]"
                >
                  Register
                </Button>
              </Link>
            </li>
          </ul>
        ) : (
          <Drawer
            sx={{ '& .MuiDrawer-paper': { width: '250px' } }}
            anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
            {drawerList}
          </Drawer>
        )}
      </header>
    </div>
  );
}