import { Text } from "../../components";
import Header from "../../components/Header";
import React from "react";
import Footer from "components/Footer";

export default function TermsConditionsPage() {
  return (
    <>
      <div className="w-full bg-white-a700">
        <div className="flex flex-col gap-[70px] lg:gap-[70px] md:gap-[52px] sm:gap-[35px]">
          <div>
            <div className="h-[560px] bg-[url(/public/images/main-banner.png)] bg-cover bg-no-repeat lg:h-auto md:h-auto">
              <div className="flex flex-col items-center gap-[34px] bg-gradient">
                <Header className="mt-[30px] w-[88%] lg:w-full lg:px-5 md:w-full md:px-5 sm:flex-col" />
                <div className="ml-[22px] mr-2 flex h-[438px] w-[86%] items-center justify-center py-[94px] lg:h-auto lg:w-full lg:px-5 lg:py-8 md:mx-0 md:h-auto md:w-full md:p-5 sm:px-5 sm:py-4">
                  <div className="container-xs mb-2.5 flex justify-center px-14 md:px-5 sm:px-4">
                    <div className="flex w-full flex-col items-center gap-[26px]">
                      <Text
                        size="textmd"
                        as="p"
                        className="text-[55px] font-medium uppercase text-black-900 lg:text-[46px] md:text-[35px] sm:text-[29px]"
                      >
                        Terms And Condition
                      </Text>
                      <Text
                        size="textlg"
                        as="p"
                        className="self-stretch text-center text-lg font-normal uppercase leading-[180%] text-[#6F6D71]"
                      >
                        Complete our quick registration form to begin, and you’ll be guided to the full application. If you have questions or run into any issues, our recruitment team is here to help—don’t hesitate to reach out.
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative flex flex-col items-center mt-[50px]">
              <div className="container-xs relative lg:px-5 md:px-5">
                <Text
                  as="p"
                  className="text-[18px] font-normal leading-[170%] text-gray-600 lg:text-[15px]"
                >
                  <span className="text-[24px] font-medium text-black-900">
                    <>
                      Introduction
                      <br />
                    </>
                  </span>
                  <span className="text-gray-600">
                    <>
                      Welcome to BLUE CHIP TECH (“we,” “our,” or “us”).
                      Our website serves as an informational and service
                      platform for BLUE CHIP TECH, specialising in
                      providing home care assistance, medical support, and
                      related services. By accessing or using this website, you
                      agree to comply with and be bound by these Terms
                      <br />
                      <br />
                    </>
                  </span>
                  <span className="text-[24px] font-medium text-black-900">
                    <>
                      Services Offered
                      <br />
                    </>
                  </span>
                  <span className="text-gray-600">
                    <>
                      BLUE CHIP TECH provides a range of services designed
                      to support individuals in their homes, including, but not
                      limited to, personal care assistance, medical support,
                      companionship, and domestic aid. Please note that while we
                      strive to provide high-quality, personalised care, our
                      services have specific limitations. For example, we do not
                      provide emergency medical services, nor do we prescribe
                      medications. For a full list of services and any
                      associated conditions or limitations, please contact us
                      directly.
                      <br />
                      <br />
                    </>
                  </span>
                  <span className="text-[24px] font-medium text-black-900">
                    <>
                      User Obligations
                      <br />
                    </>
                  </span>
                  <span className="text-gray-600">
                    <>
                      By using this website or our services, you agree to:
                      <br /> Provide accurate, current, and complete information
                      as required during service inquiries or registration.
                      <br /> Comply with any age restrictions; services are
                      intended for individuals aged 18 and older, or with
                      consent from a parent/guardian where applicable.
                      <br /> Understand and respect the limitations of our
                      services as outlined.
                      <br />
                      <br />
                    </>
                  </span>
                  <span className="text-[24px] font-medium text-black-900">
                    <>
                      Payment Terms
                      <br />
                    </>
                  </span>
                  <span className="text-gray-600">
                    <>
                      Our service pricing, payment methods, and billing cycles
                      are outlined in your service agreement. Payment is
                      required as per the selected service plan. We accept [list
                      payment methods, e.g., credit cards, bank transfers].
                      Should you wish to cancel a service, please refer to our
                      refund and cancellation policy. Refunds are processed in
                      compliance with applicable laws and are detailed in the
                      service agreement you receive upon signing up.
                      <br />
                      <br />
                    </>
                  </span>
                  <span className="text-[24px] font-medium text-black-900">
                    <>
                      Intellectual Property
                      <br />
                    </>
                  </span>
                  <span className="text-gray-600">
                    <>
                      All content on this website, including but not limited to
                      text, graphics, logos, images, and software, is owned by
                      BLUE CHIP TECH and is protected by copyright and
                      other intellectual property laws. No content may be
                      reproduced, distributed, modified, or republished without
                      our express written permission. Unauthorized use of our
                      content is strictly prohibited.
                      <br />
                      <br />
                    </>
                  </span>
                  <span className="text-[24px] font-medium text-black-900">
                    <>
                      Limitation of Liability
                      <br />
                    </>
                  </span>
                  <span className="text-gray-600">
                    <>
                      BLUE CHIP TECH is not liable for any damages, direct
                      or indirect, resulting from the use or inability to use
                      our services. This includes, but is not limited to, any
                      issues arising from third-party links or content on our
                      website. Users acknowledge and accept that while we strive
                      to offer reliable and secure services, all interactions
                      are at the user’s own risk.
                      <br />
                      <br />
                    </>
                  </span>
                  <span className="text-[24px] font-medium text-black-900">
                    <>
                      Termination
                      <br />
                    </>
                  </span>
                  <span className="text-gray-600">
                    <>
                      We reserve the right to terminate a user&#39;s access to
                      our website or services for violations of these Terms and
                      Conditions or other agreements. Users may also terminate
                      their service agreement by notifying us as outlined in
                      their service contract. Upon termination, users will lose
                      access to services, and any outstanding obligations must
                      still be fulfilled.
                      <br />
                      <br />
                    </>
                  </span>
                  <>
                    <span className="text-[24px] font-medium text-black-900">
                      Governing Law
                    </span>
                    <br />
                    <span className="text-gray-600">
                      These Terms and Conditions are governed by and construed
                      in accordance with the laws of England and Wales. By using
                      this website, you agree that any disputes will be resolved
                      under the jurisdiction of the courts of England and Wales.
                    </span>
                  </>
                </Text>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
