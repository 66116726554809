import { Text } from "../../components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ServiceCareSection from "./ServiceCareSection";
import React from "react";

export default function ServicePage() {
  return (
    <>

      <div className="flex w-full flex-col gap-[106px]  lg:gap-[106px] md:gap-[79px] sm:gap-[53px]">
        <div className="h-[560px] bg-[url(/public/images/main-banner.png)] bg-cover bg-no-repeat lg:h-auto md:h-auto">
          <div className="flex flex-col items-center gap-[34px] bg-gradient">
            <Header className="mt-[30px] w-[88%] lg:w-full lg:px-5 md:w-full md:px-5 sm:flex-col" />
            <div className="ml-[22px] mr-2 flex h-[438px] w-[86%] items-center justify-center lg:h-auto lg:w-full lg:px-5 lg:py-8 md:mx-0 md:h-auto md:w-full md:p-5 sm:px-5 sm:py-4">
              {/* bg-[url(/public/images/img_group_10.svg)] bg-cover bg-no-repeat py-[94px]  */}
              <div className="container-xs mb-2.5 flex justify-center px-14 md:px-5 sm:px-4 pb-[94px]">
                <div className="flex w-full flex-col items-center gap-[26px]">
                  <Text
                    size="textmd"
                    as="p"
                    className="text-[55px] font-medium uppercase  lg:text-[46px] md:text-[35px] sm:text-[29px] text-[#010101]"
                  >
                    Your Well-being, Our Priority
                  </Text>
                  <Text
                    size="textlg"
                    as="p"
                    className="self-stretch text-center text-lg font-normal uppercase leading-[180%] text-[#6F6D71]"
                  >
                    At  Blue Chip Tech, we focus on understanding and listening
                    to each client’s needs before creating a personalised care
                    plan, ensuring thoughtful, effective support. Our dedicated
                    team is highly skilled, experienced, and committed to
                    keeping families informed at every step. Driven by a genuine
                    passion to make a positive impact, we provide uncompromised,
                    compassionate care that truly uplifts lives. If you’re
                    looking for a reliable care provider, please call us or
                    complete the form below—our team is ready to assist..
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* service care section */}
        <ServiceCareSection />
        <Footer />
      </div>
    </>
  );
}
